export enum BlocksKeys {
  ABOUT = 'about',
  ABOUT_WITH_VIDEO = 'about_with_video',
  ADVANTAGES_TWO = 'advantages_2',
  APPLICATION_LINKS = 'application_links',
  AWARDS = 'awards',
  BENEFITS_WITH_IMAGES = 'benefits_with_images',
  BLOCK_WITH_MENU = 'block_with_menu',
  BODY_WITH_BENEFITS = 'body_with_benefits', // agency disclaimer
  CARRIERS = 'carriers',
  NODE_CUSTOM_CONTENT_ID = 'node_custom_content_id',
  PAYMENT_METHODS = 'payment_methods',
  POPULAR_QUESTION = 'popular_questions',
  PROMO_BLOCK = 'promo_block',
  REVIEW_BLOCK = 'review_block',
  SINGLE_SVG_IMAGE = 'single_svg_image',
  TEXT_BENEFITS = 'text_benefits',
}
